import { Link } from "react-router-dom";
import Email from "../../components/common/email";
import PrimaryHeader from "../../components/common/primaryHeader";
import SecondaryHeader from "./components/secondaryHeader";

function WhoIndex() {
  return (
    <>
      <div className={"min-h-screen flex flex-col"}>
        <PrimaryHeader/>
        <SecondaryHeader/>
        <div className={"flex flex w-full px-32 py-6 grow"}>
          <div>
            <div className={"bg-neutral-300 h-16 w-16 -mb-24 -ml-4"}/>
            <div className={"text-5xl font--noto-serif-regular mt-10 mb-5"}>Founding Feminist Principles</div>
            <div className={"mt-4 font--inter-regular"}>
              <div className={"w-full md:w-1/2 text-base"}>
                <div className={"text-3xl font--noto-serif-regular mb-1 mt-16"}>Allyship</div>
                <p className={"mt-2"}>
                  We don’t copyright or paywall our services — we aim to platform you and deepen the reach of your work.
                  Our efforts are guided by allyship as we prioritise under-represented communities and create systems
                  that are respectful of the lived experiences of the communities we work with.
                </p>
                <div className={"text-3xl font--noto-serif-regular mb-1 mt-8"}>Inclusivity</div>
                <p className={"mt-2"}>
                  We are committed to creating an inclusive environment that embraces diversity. Our work supports
                  women, queer, trans-led civil society groups, or (non-confirming) individuals, artists and activists.
                  If your identity falls outside of these parameters - we still welcome you!
                </p>
                <div className={"text-3xl font--noto-serif-regular mb-1 mt-8"}>Intersectionality</div>
                <p className={"mt-2"}>
                  Our approach recognizes the interconnected nature of social systems such as race, class, gender,
                  sexuality, and ability, which create overlapping and interdependent systems of discrimination or
                  disadvantage. We hope to acknowledge their existence in all we do, ensuring that our support is
                  responsive to these dimensions of identity and oppression.
                </p>
                <div className={"text-3xl font--noto-serif-regular mb-1 mt-8"}>Sustainability</div>
                <p className={"mt-2"}>
                  Our programs are designed to foster independence and self-sufficiency, enabling lasting change.
                  Instead of dismantling existing ideologies, we focus on building up key internal practices with a
                  focus on skill-building and knowledge transfer in a participatory capacity so you can carry the work
                  forward with ease of ownership.
                </p>
                <div className={"text-3xl font--noto-serif-regular mb-1 mt-8"}>Equity and Financial Justice</div>
                <p className={"mt-2"}>
                  No caveats! Our services are and will always be provided without any financial contribution from
                  partners. We seek to redress power imbalances and systemic inequalities, advocating for fair and just
                  treatment for everyone involved. So you will always be an equal throughout the partnership.
                </p>
                <p className={"mt-8"}>
                  At Iti, we desire to be so much more than a self-congratulatory philanthropic initiative operating in
                  an echo chamber. We hope to be a part of the movement, volunteering our time and skills to foster
                  slow, incremental change. By working together, we aim to be a drop in a more just, inclusive, and
                  compassionate ocean.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={"border-t bg-white flex items-center h-8 w-full text-slate-800 px-6 justify-between font--inter-regular space-x-6"}>
          <div className={"text-xs"}>
            <Email/>
          </div>
          <div className={"flex flex-row text-xs"}>
            <div className={"hover:underline hover:cursor-pointer underline-offset-4"}>
              <Link to="/for-non-profits" relative="path">
                Non Profit? Learn more here.
              </Link>
            </div>
          </div>
          <div className={"text-xs"}>
            Iti Philantropies, 2024
          </div>
        </div>
      </div>
    </>
  );
}

export default WhoIndex;
