import { NavLink } from "react-router-dom";

function NewsLineItem({ index, title, url, author, timeAgo, description }) {
    return (
        <div className={"mt-3"}>
            <div className={"hover:cursor-pointer"}>
                {index}. {title} <span className={"text-slate-500 text-xs"}>({url})</span>
            </div>
            <div className={"text-xs text-slate-500 ml-5"}>
                {author} | {timeAgo}
            </div>
            <div className={"ml-4 text-sm text-slate-500 mt-2 w-1/2"}>
                <p>
                    {description}
                </p>
            </div>
        </div>
    );
}

export default NewsLineItem;
