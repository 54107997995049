import { Link } from "react-router-dom";
import Email from "../../components/common/email";
import PrimaryHeader from "../../components/common/primaryHeader";
import SecondaryHeader from "./components/secondaryHeader";
import AditiImage from "../../static/images/team/aditi-padiyar-founder-iti.jpg";

function WhoTeam() {
  return (
    <>
      <div className={"min-h-screen flex flex-col"}>
        <PrimaryHeader/>
        <SecondaryHeader/>
        <div className={"flex w-full px-32 py-6 grow"}>
          <div>
          <div className={"bg-neutral-300 h-16 w-16 -mb-24 -ml-4"}/>
            <div className={"text-5xl font--noto-serif-regular mt-10 mb-5"}>Team</div>
            <div className={"mt-4 font--inter-regular"}>
              <div className={"w-full md:w-1/2 text-base"}>
                <img src={AditiImage} className={"h-96"}/>
                <div className={"text-3xl font--noto-serif-regular mt-4"}>Aditi Padiyar</div>
                <div className={"text-lg font--noto-serif-regular mb-1"}>Founder</div>
                <p className={"mt-4"}>
                  Aditi Padiyar is a South Asian gender and development professional with more than 5 years of
                  grantmaking and rights-based advocacy experience in South Asia and East and West Africa. She is a
                  Program Officer at Women First International Fund and a Strategic Advisor with AmplifyChange,
                  altogether managing a portfolio of more than 50 grants endorsing civil society groups in the landscape
                  of economic justice, abortion rights, menstrual wellness, CSE, transgender rights, and more.
                </p>
                <p className={"mt-4"}>
                  Aditi has worked as a Research Associate in the Philanthropic Partnerships team at Mama Cash to
                  develop and execute a prospecting strategy and mobilise resources from bilateral and multilateral
                  donors, institutional funders, and private foundations under the 2021-2030 Strategic Plan. She has
                  consulted with Global Fund for Women as a M&E officer. In 2022, she worked with UN Women Asia
                  Pacific’s social media team designing SRHR campaigns on 16 days of activism against gender-based
                  violence.
                </p>
                <p className={"mt-4"}>
                  In November 2023, Aditi Padiyar was selected as one of 20 Global Youth Fellows by NGOCSW at the
                  UNCSW68 and served as a speaker at two Conversation Circles on economic policy and social justice. She
                  is on the 2025 GYF Advisory Board. She is a Wedu leadership mentor. She is also a member of the OMLAS
                  (One Million Leaders in Asia) Champions for social justice community.
                </p>
                <p className={"mt-4"}>
                  At Women First, she worked closely with grassroots organisations in South Asia and East Africa to
                  build their technical capacity to fundraise and mobilise resources from international funds. She
                  supports these groups in identifying their strengths, documenting their work and creating outcomes
                  that align with funders so they can grow without the stress of fundraising or creating a new role
                  within small organisations that often can't afford to hire consultants.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={"border-t bg-white flex items-center h-8 w-full text-slate-800 px-6 justify-between font--inter-regular space-x-6"}>
          <div className={"text-xs"}>
            <Email/>
          </div>
          <div className={"flex flex-row text-xs"}>
            <div className={"hover:underline hover:cursor-pointer underline-offset-4"}>
              <Link to="/for-non-profits" relative="path">
                Non Profit? Learn more here.
              </Link>
            </div>
          </div>
          <div className={"text-xs"}>
            Iti Philantropies, 2024
          </div>
        </div>
      </div>
    </>
  );
}

export default WhoTeam;
