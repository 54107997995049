import Email from "../../../components/common/email";
import LeadForm from "./forms/LeadForm";
import PrimaryHeader from "../../../components/common/primaryHeader";
import SecondaryHeader from "../components/SecondaryHeader";

function NonStart() {
  return (
    <>
      <div className={"min-h-screen flex flex-col"}>
        <PrimaryHeader/>
        <SecondaryHeader/>
        <div className={"flex w-full px-6 py-6 grow"}>
          <LeadForm/>
        </div>
        <div
          className={"border-t bg-white flex items-center h-8 w-full text-slate-800 px-6 justify-between font--inter-regular space-x-6"}>
            <div className={"text-xs"}>
              <Email/>
            </div>
            <div className={"flex flex-row text-xs"}>
            </div>
            <div className={"text-xs"}>
                Iti Philantropies, 2024
            </div>
        </div>
      </div>
    </>
  );
}

export default NonStart;
