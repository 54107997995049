import { Link } from "react-router-dom";
import Email from "../../components/common/email";
import PrimaryHeader from "../../components/common/primaryHeader";
import SecondaryHeader from "./components/SecondaryHeader";

function NonServices() {
  return (
    <>
      <div className={"min-h-screen flex flex-col"}>
        <PrimaryHeader/>
        <SecondaryHeader/>
        <div className={"flex flex w-full px-6 py-6 grow w-full px-32"}>
          <div className={"w-full"}>
            <div className={"bg-neutral-300 h-16 w-16 -mb-24 -ml-4"}/>
            <div className={"text-5xl font--noto-serif-regular mt-10 mb-5"}>Repertoire of Services</div>
            <div className={"mt-4 font--inter-regular flex flex-col w-full mt-8"}>
              <div className={"w-full w-2/3 text-base flex flex-row justify-between"}>
                <div className={"flex flex-col"}>
                  <div className={"text-2xl font--noto-serif-regular mb-1"}>Strategy</div>
                  <p>
                    <li>Program Design (Donor Centric)</li>
                    <li>Strategic Planning</li>
                    <li>Logical framework and Theory of Change</li>
                    <li>Internal policies (HR, Finance, Safeguarding, etc)</li>
                  </p>
                  <div className={"text-2xl font--noto-serif-regular mb-1  mt-16"}>Data & Impact</div>
                  <p>
                    <li>Data management</li>
                    <li>Milestones and Indicators</li>
                    <li>Impact Assessment (MERL)</li>
                    <li>Metrics reporting and dashboard creation</li>
                  </p>
                </div>
                <div className={"flex flex-col"}>
                  <div className={"text-2xl font--noto-serif-regular mb-1"}>Communication</div>
                  <p>
                    <li>Proposal writing</li>
                    <li>Designing a website</li>
                    <li>Social media management</li>
                    <li>Annual reports and donor communications</li>
                    <li>Developing success stories/case studies</li>
                  </p>
                  <div className={"text-2xl font--noto-serif-regular mb-1 mt-10"}>Training & Development</div>
                  <p>
                    <li>Using grant management tools</li>
                    <li>Knowledge-sharing seminars</li>
                    <li>Strengthening staff capacity on Iti’s contributions</li>
                  </p>
                </div>
                <div className={"flex flex-col"}>
                  <div className={"text-2xl font--noto-serif-regular mb-1"}>Resource Mobilisation</div>
                  <p>
                    <li>Accessing donor platforms and solicitation</li>
                    <li>Impact investing</li>
                    <li>Crowdfunding</li>
                    <li>Accepting donations via website</li>
                  </p>
                  <div className={"text-2xl font--noto-serif-regular mb-1 mt-16"}>Rest & Recreation</div>
                  <p>
                    <li>Monthly virtual get-togethers to paint/sing/dance/vent or say hello!</li>
                    <li>Virtual art therapy sessions every month</li>
                    <li>Care Community on WhatsApp for sharing resources</li>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={"border-t bg-white flex items-center h-8 w-full text-slate-800 px-6 justify-between font--inter-regular space-x-6"}>
          <div className={"text-xs"}>
            <Email/>
          </div>
          <div className={"flex flex-row text-xs"}>
            <div className={"hover:underline hover:cursor-pointer underline-offset-4"}>
              <Link to="/for-non-profits" relative="path">
                Non Profit? Learn more here.
              </Link>
            </div>
          </div>
          <div className={"text-xs"}>
            Iti Philantropies, 2024
          </div>
        </div>
      </div>
    </>
  );
}

export default NonServices;
