import React from 'react';
import { useFormik } from 'formik';

const LeadForm = () => {
  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <div>
          <div className={"bg-neutral-300 h-16 w-16 -mb-24 -ml-4"}/>
    <div className={"text-5xl font--noto-serif-regular mt-10 mb-5"}>Start</div>
    <form onSubmit={formik.handleSubmit}>
      <label htmlFor="email">Email Address</label>
      <input
        id="email"
        name="email"
        type="email"
        onChange={formik.handleChange}
        value={formik.values.email}
      />

      <button type="submit">Submit</button>
    </form>
    </div>
  );
};

export default LeadForm;