import { Link } from "react-router-dom";
import PrimaryHeader from "../components/common/primaryHeader";
import Typewriter from 'typewriter-effect/dist/core';
import React, { useEffect } from 'react';
import HeaderImage from "../static/images/illustrations/protest-2.jpg";


const Enter = () => {
  useEffect(() => {
    let typewriter = new Typewriter('#text', {
      delay: 0,
      loop: false,
      pauseFor: 0,
    });

    typewriter.typeString(
      `
      <span className={"font--jaini-purva-regular hover:cursor-pointer"}>इति</span> (iti) is a philanthropic cooperative, valuing collective effort and mutual support. By fostering a sense of sisterhood and solidarity, we aim to create a network where everyone can thrive. Iti’s success as a social enterprise is intertwined with the success of the communities we serve. 
      `
    ).start();


    return () => {};
  }, []);

  return (
    <>
      <div className={"flex flex-col min-h-screen"}>
        <PrimaryHeader/>
        <div className={"flex flex-col w-full px-32 py-6 min-h-screen"}>
          <div className="mt-24 flex flex-row">
            <div className={"w-1/2"}>
              <div className={"bg-neutral-300 h-16 w-16 -mb-24 -ml-4"}/>
              <div className={"text-6xl font--noto-serif-regular mt-10 mb-5"}>Nothing<br></br>about us<br></br>without us</div>
              <div className={"mt-4 flex flex-row font--inter-regular w-screen"}>
                <div className={"w-1/3"}>
                  <div className={"w-fit text-xl mt-10 leading-10"}>
                    <p id="text" className="h-64">
                      <span className={"font--jaini-purva-regular hover:cursor-pointer"}>इति</span> is a philanthropic cooperative, valuing collective effort and mutual support. By fostering a sense of sisterhood and solidarity, we aim to create a network where everyone can thrive. Iti’s success as a social enterprise is intertwined with the success of the communities we serve. 
                    </p>
                  </div>
                  <div className={"text-lg inline-grid grid-cols-2 gap-0 mt-5"}>
                    <Link to="/who-is-iti/founding-feminist-principles" relative="path" className={"p-0 m-0 w-fit"}>
                      <button className={"button-iti"}>Who is iti?</button>
                    </Link>
                    <Link to="/for-non-profits" className={"p-0 m-0 w-fit -ml-5"}>
                      <button className={"button-iti"}>For Non Profits</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className={"w-1/2 flex flex-col items-center justify-center"}>
              <div>
                <img src={HeaderImage} className={"max-w-full"}/>
                <p className={"font--inter text-sm text-gray-500"}>
                  Caption Photo, 2023.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={"border-t bg-white flex items-center h-8 w-full text-slate-800 px-6 justify-between font--inter-regular space-x-6"}>
            <div className={"text-xs"}>
                <Link to="/for-non-profits" relative="path">
                    contact@itiforgood.org
                </Link>
            </div>
            <div className={"flex flex-row text-xs"}>
              
            </div>
            <div className={"text-xs"}>
                Iti Philantropies, 2024
            </div>
        </div>
      </div>
    </>
  );
}

export default Enter;
