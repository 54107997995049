import { Link } from "react-router-dom";
import Email from "../../components/common/email";
import PrimaryHeader from "../../components/common/primaryHeader";
import SecondaryHeader from "./components/SecondaryHeader";

function NonResources() {
  return (
    <>
      <div className={"min-h-screen flex flex-col"}>
        <PrimaryHeader/>
        <SecondaryHeader/>
        <div className={"flex flex w-full px-32 py-6 grow"}>
          <div>
          <div className={"bg-neutral-300 h-16 w-16 -mb-24 -ml-4"}/>
            <div className={"text-5xl font--noto-serif-regular mt-10 mb-5"}>Resources</div>
            <div className={"font--andika-regular flex flex-row space-x-3 mt-4"}>
              <div className={"underline underline-offset-4 cursor-pointer"}>
                <span className={"text-xl"}>#</span>
                <span>GrantAnnouncements</span>
              </div>
              <div className={"underline underline-offset-4 cursor-pointer"}>
                <span className={"text-xl"}>#</span>
                <span>SelfAssesments</span>
              </div>
              <div className={"underline underline-offset-4 cursor-pointer"}>
                <span className={"text-xl"}>#</span>
                <span>FoundationDirectories</span>
              </div>
              <div className={"underline underline-offset-4 cursor-pointer"}>
                <span className={"text-xl"}>#</span>
                <span>FundingOpportunities</span>
              </div>
              <div className={"underline underline-offset-4 cursor-pointer"}>
                <span className={"text-xl"}>#</span>
                <span>Training</span>
              </div>
              <div className={"underline underline-offset-4 cursor-pointer"}>
                <span className={"text-xl"}>#</span>
                <span>FeministsToFollow</span>
              </div>
              <div className={"underline underline-offset-4 cursor-pointer"}>
                <span className={"text-xl"}>#</span>
                <span>Webinars</span>
              </div>
              <div className={"underline underline-offset-4 cursor-pointer"}>
                <span className={"text-xl"}>#</span>
                <span>Toolkits</span>
              </div>
              <div className={"underline underline-offset-4 cursor-pointer"}>
                <span className={"text-xl"}>#</span>
                <span>Jobs</span>
              </div>
            </div>
            <div className={"w-1/2"}>
              <div className={"mt-8 grid grid-rows-4 grid-flow-col gap-2"}>
                <div>
                  <a
                    href={"https://oxfamilibrary.openrepository.com/bitstream/handle/10546/188709/ml-mainstreaming-gender-justice-approach-270910-en.pdf;jsessionid=62F54ABF045578F1C10E816E44310DD5?sequence=3"}>
                    <div className={"border border-slate-400 hover:border-dashed rounded w-64 h-24 p-2 cursor-pointer"}>
                      <p>Mainstreaming a Gender Justice Approach </p>
                      <div className={"underline underline-offset-4 text-xs mt-2"}>
                        <span className={"text-sm"}>#</span>
                        <span>SelfAssesments</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div>
                  <a
                    href={"https://www.connectiveimpact.com/resources"}>
                    <div className={"border border-slate-400 hover:border-dashed rounded w-64 h-24 p-2 cursor-pointer"}>
                      <p>Connective Impact / Resources </p>
                      <div className={"underline underline-offset-4 text-xs mt-2"}>
                        <span className={"text-sm"}>#</span>
                        <span>SelfAssesments</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div>
                  <a
                    href={"https://www.linkedin.com/company/justicefunds/"}>
                    <div className={"border border-slate-400 hover:border-dashed rounded w-64 h-24 p-2 cursor-pointer"}>
                      <p>Justice Funds </p>
                      <div className={"underline underline-offset-4 text-xs mt-2"}>
                        <span className={"text-sm"}>#</span>
                        <span>FundingOpportunities</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div>
                  <a
                    href={"https://startsmall.llc/#cta"}>
                    <div className={"border border-slate-400 hover:border-dashed rounded w-64 h-24 p-2 cursor-pointer"}>
                      <p>Start Small </p>
                      <div className={"underline underline-offset-4 text-xs mt-2"}>
                        <span className={"text-sm"}>#</span>
                        <span>FundingOpportunities</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div>
                  <a
                    href={"https://www.fundsforngos.org/"}>
                    <div className={"border border-slate-400 hover:border-dashed rounded w-64 h-24 p-2 cursor-pointer"}>
                      <p>Funds for NGOs </p>
                      <div className={"underline underline-offset-4 text-xs mt-2"}>
                        <span className={"text-sm"}>#</span>
                        <span>FundingOpportunities</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div>
                  <a
                    href={"https://www.devnetjobs.org/"}>
                    <div className={"border border-slate-400 hover:border-dashed rounded w-64 h-24 p-2 cursor-pointer"}>
                      <p>DevNet Jobs </p>
                      <div className={"underline underline-offset-4 text-xs mt-2"}>
                        <span className={"text-sm"}>#</span>
                        <span>Jobs</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div>
                  <a
                    href={"https://fconline.foundationcenter.org/"}>
                    <div className={"border border-slate-400 hover:border-dashed rounded w-64 h-24 p-2 cursor-pointer"}>
                      <p>Foundation Directory </p>
                      <div className={"underline underline-offset-4 text-xs mt-2"}>
                        <span className={"text-sm"}>#</span>
                        <span>FoundationDirectories</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div>
                  <a
                    href={"https://lorettajross.com/"}>
                    <div className={"border border-slate-400 hover:border-dashed rounded w-64 h-24 p-2 cursor-pointer"}>
                      <p>Loretta R. Ross </p>
                      <div className={"underline underline-offset-4 text-xs mt-2"}>
                        <span className={"text-sm"}>#</span>
                        <span>FeministsToFollow</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div>
                  <a
                    href={"https://www.awid.org/witm-toolkit"}>
                    <div className={"border border-slate-400 hover:border-dashed rounded w-64 h-24 p-2 cursor-pointer"}>
                      <p>WITM Toolkit </p>
                      <div className={"underline underline-offset-4 text-xs mt-2"}>
                        <span className={"text-sm"}>#</span>
                        <span>ToolKits</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div>
                  <a
                    href={"https://cofemsocialchange.org/learning-advocacy-tools/feminist-research-assessment-tool/"}>
                    <div className={"border border-slate-400 hover:border-dashed rounded w-64 h-24 p-2 cursor-pointer"}>
                      <p>Feminist Research Assesment Tool </p>
                      <div className={"underline underline-offset-4 text-xs mt-2"}>
                        <span className={"text-sm"}>#</span>
                        <span>ToolKits</span>
                      </div>
                    </div>
                  </a>
                </div>
                <div>
                  <a
                    href={"https://drive.google.com/file/d/1VLVEdejfbgvCbIhlksMpZxbLjDvjWzW4/view"}>
                    <div className={"border border-slate-400 hover:border-dashed rounded w-64 h-24 p-2 cursor-pointer"}>
                      <p>Feminist Realties Toolkit </p>
                      <div className={"underline underline-offset-4 text-xs mt-2"}>
                        <span className={"text-sm"}>#</span>
                        <span>ToolKits</span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={"border-t bg-white flex items-center h-8 w-full text-slate-800 px-6 justify-between font--inter-regular space-x-6"}>
          <div className={"text-xs"}>
            <Email/>
          </div>
          <div className={"flex flex-row text-xs"}>
            <div className={"hover:underline hover:cursor-pointer underline-offset-4"}>
              <Link to="/who-is-iti" relative="path">
                Learn more about iti here
              </Link>
            </div>
          </div>
          <div className={"text-xs"}>
            Iti Philantropies, 2024
          </div>
        </div>
      </div>
    </>
  );
}

export default NonResources;
