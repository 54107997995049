import { Link } from "react-router-dom";

function Email() {
    return (
        <>
            <Link to="mailto:contact@itiforgood.org" relative="path">
                contact@itiforgood.org
            </Link>
        </>
    )
}

export default Email;
