import { NavLink } from "react-router-dom";
import Logo from "./logo";

function PrimaryHeader() {
    return (
        <>
            <div
                className={"flex items-center h-16 w-full px-32 justify-between border-b-2 border-black fixed bg-white"}>
            <Logo/>
            <div className={"flex font--andika-regular space-x-6"}>
                <div className={"hover:underline hover:cursor-pointer underline-offset-4"}>
                <NavLink to="/who-is-iti/founding-feminist-principles" relative="path" className={({ isActive }) => (isActive ? 'underline underline-offset-4' : '')}>
                    Who is iti?
                </NavLink>
                </div>
                <div className={"hover:underline hover:cursor-pointer underline-offset-4"}>
                <NavLink to="/for-non-profits" relative="path" className={({ isActive }) => (isActive ? 'underline underline-offset-4' : '')}>
                    For Non-Profits
                </NavLink>
                </div>
            </div>
            <div>
                <div className={"hover:underline hover:cursor-pointer underline-offset-4 font--andika-regular text-base"}>
                <NavLink to="/news" relative="path" className={({ isActive }) => (isActive ? 'underline underline-offset-4' : '')}>
                    News
                </NavLink>
                </div>
            </div>
            </div>
        </>
    )
}

export default PrimaryHeader;
