import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Enter from './routes/Enter';
import WhoIndex from './routes/who/Index';
import WhoTeam from "./routes/who/Team";
import NonIndex from './routes/non/Index';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import NewsIndex from './routes/news/Index';
import ErrorPage from "./error-page";
import NonStart from "./routes/non/start/Start";
import NonServices from "./routes/non/Services";
import NonPartnerships from "./routes/non/Partnerships";
import NonResources from "./routes/non/Resources";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Enter />,
    errorElement: <ErrorPage />,
  },
  // All the for non-profits pages
  {
    path: "/for-non-profits",
    element: <NonIndex />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/services",
    element: <NonServices />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/partnerships",
    element: <NonPartnerships />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/resources",
    element: <NonResources />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/start",
    element: <NonStart />,
    errorElement: <ErrorPage />,
  },
  // All the Who is iti pages
  {
    path: "/who-is-iti/founding-feminist-principles",
    element: <WhoIndex />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/who-is-iti/team",
    element: <WhoTeam />,
    errorElement: <ErrorPage />,
  },
  // All the news pages
  {
    path: "/news",
    element: <NewsIndex />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
