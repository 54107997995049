import { Link } from "react-router-dom";
import Email from "../../components/common/email";
import PrimaryHeader from "../../components/common/primaryHeader";
import SecondaryHeader from "./components/SecondaryHeader";
import Section1Image from "../../static/images/illustrations/services/section-1.jpg"
import Section2Image from "../../static/images/illustrations/services/section-2.jpg"
import Section3Image from "../../static/images/illustrations/services/section-3.jpg"
import Section4Image from "../../static/images/illustrations/services/section-4.jpg"
import Section5Image from "../../static/images/illustrations/services/section-5.jpg"

function NonIndex() {
  return (
    <>
      <div className={"min-h-screen flex flex-col"}>
        <PrimaryHeader/>
        <SecondaryHeader/>
        <div className={"snap-mandatory snap-y overflow-y-scroll max-h-screen"}>
          <div className={"snap-always snap-center flex w-full py-6 grow min-h-screen"} style={{backgroundImage: `linear-gradient(black, black), url(${Section1Image})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundBlendMode: "saturation"}}>
            <div className={"px-32 pt-12"}>
              <div className={"bg-black h-16 w-16 -mb-24 -ml-4"} />
              <div className={"text-5xl font--noto-serif-regular mt-10 mb-5 text-white text-6xl"}>Iti For You</div>
              <div className={"mt-12 text-xl font--inter-regular"}>
                <div className={"w-full md:w-2/3 leading-loose mt-12"}>
                  <p>
                    <span className={"bg-white text-3xl"}>There are over 3.3 million NGOs in India. Of these, 2 lakh NGOs  are registered civil society groups. 68 thousand work on gender justice. work out of semi-urban or rural parts of the country. Most of them are unregistered community based organisations and self help groups working in some of the most remote parts of the country. Where the roads disappear, water dries up, and power lines don't run.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={"snap-always snap-center flex w-full py-6 grow min-h-screen"} style={{backgroundImage: `linear-gradient(black, black), url(${Section2Image})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundBlendMode: "saturation"}}>
            <div className={"px-32"}>
              <div className={"mt-12 text-xl font--inter-regular"}>
                <div className={"w-full md:w-2/3 leading-loose"}>
                    <p>
                      <span className={"bg-white text-3xl"}>While rural India has more than 425 million internet users, only 3% of them have access to quality English education. While India’s nonprofit industry receives nearly 2 billion USD annually from foreign sources, what percentage goes outside Delhi, Mumbai and Bangalore? To Tripura, Madurai, Dharnai, Jhansi, Bilaspur, Marathwada?</span>
                    </p>
                </div>
              </div>
            </div>
          </div>
          <div className={"snap-always snap-center flex w-full py-6 grow min-h-screen"} style={{backgroundImage: `linear-gradient(black, black), url(${Section3Image})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundBlendMode: "saturation"}}>
            <div className={"px-32"}>
              <div className={"mt-12 text-xl font--inter-regular"}>
                <div className={"w-full md:w-2/3 leading-loose"}>
                    <p>
                      <span className={"bg-white text-3xl"}>And finally, how many of these unregistered self help groups working on gender justice, minority rights, abortion advocacy, and SRHR have access to the 2 billion? To Global North funding? How does access tie in with language justice? </span>
                    </p>
                </div>
              </div>
            </div>
          </div>
          <div className={"snap-always snap-center flex w-full py-6 grow min-h-screen"} style={{backgroundImage: `linear-gradient(black, black), url(${Section4Image})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundBlendMode: "saturation"}}>
            <div className={"px-32"}>
              <div className={"mt-12 text-xl font--inter-regular"}>
                <div className={"w-full md:w-2/3 leading-loose"}>
                    <p>
                      <span className={"bg-white text-3xl"}>How do these groups — that struggle to justify their intentions as civil society shrinks under the growing threat of FCRA — navigate complex foreign grantmaking structures? How do they write multi-lingual proposals, seek audited financial statements, document compelling case studies, create internal policies and structures and many such internal hurdles because they can even make themselves eligible for the funding they rightfully deserve? How do they attract talent and work on organisational strengthening when they are never able to rise about survival? </span>
                    </p>
                </div>
              </div>
            </div>
          </div>
          <div className={"snap-always snap-center flex w-full py-6 grow min-h-screen"} style={{backgroundImage: `linear-gradient(black, black), url(${Section5Image})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundBlendMode: "saturation"}}>
            <div className={"px-32"}>
              <div className={"mt-12 text-xl font--inter-regular"}>
                <div className={"w-full md:w-2/3 leading-loose"}>
                  <p className="mt-4">
                    <span className={"font--inter-bold bg-white text-3xl"}>The questions may not end. But some answers begin <Link to="/start" relative="path"><span className={"underline hover:cursor-pointer"}>here</span></Link>.</span>
                    <br></br>
                  </p>
                  <div className={"p-0 m-0 w-fit mt-12 mb-5 bg-white"}>
                    <Link to="/start.html">
                      <button className={"button-iti"}>Apply</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* No footer on this page, it looks weird with the photo backgrounds. */}
      </div>
    </>
  );
}

export default NonIndex;
