import { Link } from "react-router-dom";

function Logo() {
    return (
        <>
        <div className={"text-4xl font--jaini-purva-regular hover:cursor-pointer"}>
            <Link to="/" relative="path">
            इति
            </Link>
        </div>
        </>
    )
}

export default Logo;