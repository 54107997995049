import { NavLink } from "react-router-dom";

function SecondaryHeader() {
    return (
        <>
            <div
                className={"flex items-center w-full px-32 justify-between text-sm"}>
                <div className={"flex flex-row space-x-4 text-lg mt-4 mb-4"}>
                    <div>
                    <NavLink to="/for-non-profits" relative="path" className={({ isActive }) => (isActive ? 'underline underline-offset-4' : 'hover:underline hover:cursor-pointer underline-offset-4')}>
                        Iti For You
                    </NavLink>
                    </div>
                    <div>
                    <NavLink to="/services" relative="path" className={({ isActive }) => (isActive ? 'underline underline-offset-4' : 'hover:underline hover:cursor-pointer underline-offset-4')}>
                        Repertoire of Services
                    </NavLink>
                    </div>
                    <div>
                    <NavLink to="/partnerships" relative="path" className={({ isActive }) => (isActive ? 'underline underline-offset-4' : 'hover:underline hover:cursor-pointer underline-offset-4')}>
                        Partnership Model
                    </NavLink>
                    </div>
                    <div>
                    <NavLink to="/resources" relative="path" className={({ isActive }) => (isActive ? 'underline underline-offset-4' : 'hover:underline hover:cursor-pointer underline-offset-4')}>
                        Resources
                    </NavLink>
                    </div>
                    <div>
                    <NavLink to="/start" relative="path" className={({ isActive }) => (isActive ? 'underline underline-offset-4' : 'hover:underline hover:cursor-pointer underline-offset-4')}>
                        Start
                    </NavLink>
                    </div>
                </div>
                <div className={"flex font--andika-regular space-x-6"}>
                </div>
                <div>
                </div>
            </div>
        </>
    )
}

export default SecondaryHeader;