import {Link} from "react-router-dom";
import Logo from "../../components/common/logo";
import Email from "../../components/common/email";
import NewsLineItem from "./components/newsLineItem";
import PrimaryHeader from "../../components/common/primaryHeader";
import SecondaryHeader from "../who/components/secondaryHeader";

const LATEST_NEWS_ITEMS = [
  {
    title: "Empowering Women Through Community Social Work",
    url: "socialworktoday.com",
    author: "johndoe",
    timeAgo: "1 hour ago",
    description: "A new initiative focuses on empowering women through community-based social work, offering support and resources to those in need."
  },
  {
    title: "Innovative Approaches to Gender Equality in Social Services",
    url: "feministnews.org",
    author: "janedoe",
    timeAgo: "3 hours ago",
    description: "Gender equality in social services is being advanced through innovative approaches that prioritize women's rights and well-being."
  },
  {
    title: "How Social Workers Are Addressing Domestic Violence",
    url: "womenshealthmag.com",
    author: "emilyclark",
    timeAgo: "2 hours ago",
    description: "Social workers are playing a crucial role in addressing domestic violence, providing essential support and resources to survivors."
  }
];


const NEWS_ITEMS = [
  {
    title: "Empowering Women Through Community Social Work",
    url: "socialworktoday.com",
    author: "johndoe",
    timeAgo: "1 hour ago",
    description: "A new initiative focuses on empowering women through community-based social work, offering support and resources to those in need."
  },
  {
    title: "Innovative Approaches to Gender Equality in Social Services",
    url: "feministnews.org",
    author: "janedoe",
    timeAgo: "3 hours ago",
    description: "Gender equality in social services is being advanced through innovative approaches that prioritize women's rights and well-being."
  },
  {
    title: "How Social Workers Are Addressing Domestic Violence",
    url: "womenshealthmag.com",
    author: "emilyclark",
    timeAgo: "2 hours ago",
    description: "Social workers are playing a crucial role in addressing domestic violence, providing essential support and resources to survivors."
  },
  {
    title: "The Role of Feminist Theory in Modern Social Work",
    url: "socialworkreview.com",
    author: "markjohnson",
    timeAgo: "4 hours ago",
    description: "Feminist theory is shaping modern social work practices, emphasizing the importance of addressing gender inequalities."
  },
  {
    title: "Supporting Single Mothers Through Targeted Social Programs",
    url: "parentinglife.com",
    author: "sarahjones",
    timeAgo: "5 hours ago",
    description: "New social programs are being developed to provide targeted support for single mothers, helping them achieve financial stability and independence."
  },
  {
    title: "Advocating for Women's Rights in the Social Work Field",
    url: "womensrights.org",
    author: "alexmartin",
    timeAgo: "6 hours ago",
    description: "Social workers are at the forefront of advocating for women's rights, pushing for policy changes that benefit women across the globe."
  },
  {
    title: "Creating Safe Spaces for Women Through Social Work",
    url: "communitycare.co.uk",
    author: "lindawilliams",
    timeAgo: "1 day ago",
    description: "Efforts are being made to create safe spaces for women through social work, ensuring their safety and well-being in various environments."
  },
  {
    title: "Empowerment Workshops for Women Led by Social Workers",
    url: "empowermentnow.com",
    author: "nancyadams",
    timeAgo: "8 hours ago",
    description: "Social workers are leading empowerment workshops for women, providing them with tools and strategies for personal growth and empowerment."
  },
  {
    title: "Intersectionality in Feminist Social Work",
    url: "diversitytoday.com",
    author: "michaelbrown",
    timeAgo: "9 hours ago",
    description: "The concept of intersectionality is becoming increasingly important in feminist social work, addressing the multiple layers of discrimination women face."
  },
  {
    title: "Mental Health Support for Women in Crisis",
    url: "mentalhealthmatters.org",
    author: "carolsimmons",
    timeAgo: "10 hours ago",
    description: "Social workers are providing crucial mental health support for women in crisis, helping them navigate their challenges and find stability."
  },
  {
    title: "Feminist Social Work: Changing Lives, One Woman at a Time",
    url: "changemakers.com",
    author: "patriciataylor",
    timeAgo: "12 hours ago",
    description: "Feminist social work is making a significant impact by changing lives one woman at a time through dedicated and compassionate service."
  },
  {
    title: "Addressing Economic Inequality Through Feminist Social Work",
    url: "economictimes.com",
    author: "robertsmith",
    timeAgo: "14 hours ago",
    description: "Economic inequality is being tackled through feminist social work, with a focus on uplifting women from marginalized communities."
  },
  {
    title: "Promoting Education for Girls in Underserved Areas",
    url: "educationforall.org",
    author: "melissajackson",
    timeAgo: "16 hours ago",
    description: "Social workers are promoting education for girls in underserved areas, ensuring they have access to quality education and opportunities."
  },
  {
    title: "Combating Human Trafficking Through Social Work",
    url: "humanrightsnews.com",
    author: "kevinlee",
    timeAgo: "18 hours ago",
    description: "Efforts to combat human trafficking are being strengthened by social workers, providing support and rehabilitation for survivors."
  },
  {
    title: "The Future of Feminist Social Work: Trends and Predictions",
    url: "futureofsocialwork.com",
    author: "ashleywhite",
    timeAgo: "20 hours ago",
    description: "Experts discuss the future of feminist social work, exploring emerging trends and predictions for the field's development."
  }
];

function NewsIndex() {
  return (
    <>
      <div className={"min-h-screen flex flex-col"}>
        <PrimaryHeader/>
        <SecondaryHeader/>
        <div className={"flex flex flex-col w-full px-32 py-6 grow"}>
          <div>
          <div className={"bg-neutral-300 h-16 w-16 -mb-24 -ml-4"}/>
            <div className={"text-5xl font--noto-serif-regular mt-10 mb-5"}>New</div>
            <div>
              {LATEST_NEWS_ITEMS.map((item, index) => (
                <NewsLineItem
                  key={index + 1}
                  index={index + 1}
                  title={item.title}
                  url={item.url}
                  author={item.author}
                  timeAgo={item.timeAgo}
                  description={item.description}
                />
              ))}
            </div>
          </div>
          <div className={"mt-12"}>
          <div className={"bg-neutral-300 h-16 w-16 -mb-24 -ml-4"}/>
            <div className={"text-5xl font--noto-serif-regular mt-10 mb-5"}>Archives</div>
            <div>
              {NEWS_ITEMS.map((item, index) => (
                <NewsLineItem
                  key={index + 1}
                  index={index + 1}
                  title={item.title}
                  url={item.url}
                  author={item.author}
                  timeAgo={item.timeAgo}
                  description={item.description}
                />
              ))}
            </div>
          </div>
        </div>
        <div
          className={"border-t bg-white flex items-center h-8 w-full text-slate-800 px-6 justify-between font--inter-regular space-x-6"}>
          <div className={"text-xs"}>
            <Email/>
          </div>
          <div className={"flex flex-row text-xs"}>
            <div className={"hover:underline hover:cursor-pointer underline-offset-4"}>
              <Link to="/" relative="path">
                itiforgood.org
              </Link>
            </div>
          </div>
          <div className={"text-xs"}>
            Iti Philantropies, 2024
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsIndex;
