import {Link} from "react-router-dom";
import Email from "../../components/common/email";
import PrimaryHeader from "../../components/common/primaryHeader";
import SecondaryHeader from "./components/SecondaryHeader";

function NonPartnerships() {
  return (
    <>
      <div className={"flex flex-col"}>
        <PrimaryHeader/>
        <SecondaryHeader/>
        <div className={"flex w-full px-32 py-6 grow"}>
          <div>
            <div className={"bg-neutral-300 h-16 w-16 -mb-24 -ml-4"}/>
            <div className={"text-5xl font--noto-serif-regular mt-10 mb-5"}>Partnership Model</div>
            <div className={"text-2xl font--noto-serif-regular mt-5"}>Pre-Project</div>
            <div className={"text-xl font--noto-serif-regular mt-5"}>Expression of Interest</div>
            <p className={"mt-1 w-1/2"}>
              Sign up to partner with Iti using our Expression of Interest <a href={"/start"}
                                                                              className={"underline underline-offset-4"}>form</a>.
              Provide detailed information on the
              areas needing intervention. We will review your request, website (if applicable), and any
              shared documents in 7 to 14 days to determine our approach. We may reach out during this time to
              request further information.
            </p>
            <div className={"text-xl font--noto-serif-regular mt-10"}>Introductions & Assessment</div>
            <p className={"mt-1 w-1/2 mb-10"}>
              In this initial phase, we establish a strong foundation by getting to know your team and understanding
              your business environment. We conduct thorough assessments through discussions and data gathering
              to identify your needs, goals, and current challenges. This comprehensive analysis allows us to
              pinpoint key areas for improvement and craft a strategic plan tailored to your specific requirements.
            </p>
            <hr className={"w-1/2"}/>
            <div className={"text-2xl font--noto-serif-regular mt-10"}>Project</div>
            <div className={"text-lg font--noto-serif-regular mt-3"}>Phase 1 - Discovery</div>
            <div className={"text-sm font--noto-serif-regular text-slate-500"}>2-3 weeks</div>
            <p className={"mt-1 w-1/2"}>
              In the discovery phase, we work closely with you to understand your needs, goals, and current challenges.
              This involves conducting interviews, gathering data, and analyzing your existing processes to identify
              problems and opportunities. The result is a detailed assessment report and a proposed plan tailored to
              your unique situation.
            </p>
            <div className={"text-lg font--noto-serif-regular mt-3"}>Phase 2 - Implementation</div>
            <div className={"text-sm font--noto-serif-regular text-slate-500"}>2-6 weeks</div>
            <p className={"mt-1 w-1/2"}>
              During the implementation phase, we execute the agreed-upon plan to address your challenges and leverage
              opportunities. This involves developing solutions, providing necessary training, and implementing changes.
              We manage the project closely to ensure everything is on track, delivering new systems, processes,
              or strategies, along with regular progress updates.
            </p>
            <div className={"text-lg font--noto-serif-regular mt-3"}>Phase 3 - Evaluation</div>
            <div className={"text-sm font--noto-serif-regular text-slate-500"}>1-2 weeks</div>
            <p className={"mt-1 w-1/2 mb-10"}>
              In the evaluation phase, we assess the success of the implementation and ensure the changes are
              sustainable.
              We measure outcomes, gather feedback, provide support and necessary adjustments, and document lessons
              learned.
              The result is a final project report with performance metrics and recommendations for future improvements.
            </p>
            <hr className={"w-1/2"}/>
            <div className={"text-2xl font--noto-serif-regular mt-10"}>Post Project</div>
            <p className={"mt-1 w-1/2"}>
              Our commitment to your success doesn't end with the project's completion. We provide ongoing support to
              ensure the solutions we implemented continue to deliver results. Whether you need technical assistance,
              additional training, or strategic advice, we're here to help you navigate any challenges and seize new
              opportunities.
            </p>
            <div className={"text-lg font--noto-serif-regular mt-3"}>Virtual Exposure Visits</div>
            <p className={"mt-1 w-1/2"}>
              Experience best practices and innovative solutions firsthand with our virtual exposure visits. We connect
              you with industry leaders and showcase successful implementations through virtual tours and interactive
              sessions. This exposure helps you gain valuable insights and inspiration to apply cutting-edge strategies
              within your own organization.
            </p>
            <div className={"text-lg font--noto-serif-regular mt-3"}>Check-ins</div>
            <div className={"text-sm font--noto-serif-regular text-slate-500"}>monthly or quarterly</div>
            <p className={"mt-1 w-1/2"}>
              To maintain momentum and ensure sustained success, we conduct quarterly check-ins. These sessions allow us
              to review progress, address any emerging issues, and refine strategies as needed. By staying engaged and
              proactive, we help you achieve continuous improvement and long-term growth.
            </p>
            <div className={"text-lg font--noto-serif-regular mt-3"}>Partners for life!</div>
            <p className={"mt-1 w-1/2"}>
              We believe in building lasting relationships with our clients. As your trusted partner, we are dedicated
              to your ongoing success. From initial engagement to long-term collaboration, we are committed to
              supporting your business goals and helping you thrive in an ever-evolving landscape. Together, we can
              achieve great things!
            </p>
          </div>
        </div>
        <div
          className={"border-t bg-white flex items-center h-8 w-full text-slate-800 px-6 justify-between font--inter-regular space-x-6"}>
          <div className={"text-xs"}>
            <Email/>
          </div>
          <div className={"flex flex-row text-xs"}>
            <div className={"hover:underline hover:cursor-pointer underline-offset-4"}>
              <Link to="/who-is-iti" relative="path">
                Learn more about iti here
              </Link>
            </div>
          </div>
          <div className={"text-xs"}>
            Iti Philantropies, 2024
          </div>
        </div>
      </div>
    </>
  );
}

export default NonPartnerships;
